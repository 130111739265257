import React from "react"

import useSiteMetadata from "../hooks/use-site-metadata"

import Page from "../components/page"
import SEO from "../components/seo"

import Portfolio from "../containers/portfolio"

const IndexPage = () => {
  const { 
    artist, 
    description,
    facebook,
    twitter,
    instagram, 
    soundcloud, 
    youtube
  } = useSiteMetadata()
  return(
    <Page>
      <SEO title="HOME" description={description}/>
        <Portfolio
          artist={artist}
          social={{ instagram, soundcloud, youtube, facebook, twitter}}
        />
    </Page>
  )
}

export default IndexPage
