import React from "react"
import PropTypes from "prop-types"

import { renderSocialIcons } from "../../lib/utils"

const Widgets = ({ social, incrementSlider, decrementSlider }) => {
    return(
        <div className="portfolio__widget--container animated fadeIn delay-4s">
            <div className="portfolio__widget--social-bar">
                {renderSocialIcons(social)}
            </div>
            <div className="portfolio__widget--counter-container">
                <a 
                    href="#" 
                    className="portfolio__slideshow--player-btn fas fa-play-circle"
                    onClick={decrementSlider}
                />
                <a 
                    href="#" 
                    className="portfolio__slideshow--player-btn fas fa-play-circle"
                    onClick={incrementSlider}
                />
            </div>
        </div>
    )
}

Widgets.propTypes = {
    social: PropTypes.shape({
        facebook: PropTypes.string,
        instagram: PropTypes.string,
        soundcloud: PropTypes.string,
        twitter: PropTypes.string,
        youtube: PropTypes.string
    }).isRequired,
    incrementSlider: PropTypes.func.isRequired,
    decrementSlider: PropTypes.func.isRequired
}

export default Widgets