import React from "react"
import PropTypes from "prop-types"

import Seperator from "../seperator"

const Navigation = ({ active, tracks, setIndex }) => {
    return(
        <div className="portfolio__navigation--container">
            <div className="portfolio__navigation--counter animated fadeInRight slower delay-5s">
                {tracks.map((_, id) =>
                    <a 
                        href="#"
                        key={id.toString()}
                        className={id === active ? 'active' : undefined}
                        children={`0` + (id + 1)}
                        onClick={() => setIndex(id)}
                    />
                )}
                <div className="portfolio__navigation--scroll-indicator">
                    <span>
                        SCROLL
                    </span>
                    <span>
                        <Seperator rotate={90} spacing={60}/>
                    </span>
                </div>
            </div>
        </div>
    )
}

Navigation.propTypes = {
    active: PropTypes.number.isRequired,
    tracks: PropTypes.array.isRequired,
    setIndex: PropTypes.func.isRequired
}

export default Navigation