import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { fromCDN } from "../../lib/utils"

const Slideshow = ({ song, player }) => {
    const { 
        coverImage: { url } 
    } = song
    const sliderStyle = {
        backgroundImage: `url('${ fromCDN(url) }')`,
        backgroundSize: `contain`,
    }
    const { 
        isPlaying,
        setPlaying
    } = player
    return(
        <div className="portfolio__slideshow--container animated fadeIn slow">
            <div 
                className="portfolio__slideshow--player"
                style={sliderStyle}
            >
                { isPlaying ? 
                    <a 
                        href="#"
                        onClick={() => setPlaying(false)}
                        className="portfolio__slideshow--player-btn fas fa-pause-circle animated infinite pulse"
                    /> :
                    <a
                        href="#"
                        onClick={() => setPlaying(true)}
                        className="portfolio__slideshow--player-btn fas fa-play-circle animated infinite pulse"  
                    />
                }
            </div>
        </div>
    )
}

Slideshow.propTypes = {
    song: PropTypes.shape({
        coverImage: PropTypes.shape({
            url: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    player: PropTypes.shape({
        isPlaying: PropTypes.bool.isRequired,
        setPlaying: PropTypes.func.isRequired
    }).isRequired
}

export default Slideshow