import React from "react"
import PropTypes from "prop-types"

import useFeaturedSongsList from "../hooks/use-featured-songs"
import useSlideShow from "../hooks/use-slideshow"
import useMusicPlayer from "../hooks/use-music-player"
import { fromCDN } from "../lib/utils"

import {
    PortfolioWidgets,
    PortfolioDescription,
    PortfolioNavigation,
    PortfolioSlideshow
} from "../components/portfolio"
import Loader from "../components/loader"


const Portfolio = ({ artist, social }) => {
    const { data, loading } = useFeaturedSongsList()
    const { songs } = loading ? { songs: [] } : data

    const slider = useSlideShow(songs)
    const musicPlayer = useMusicPlayer(`#player`)
    
    if(loading) return (
        <>
            <Loader fullscreen palette={[`#c31432`, `#240b36`]}/>
            <audio id="player"/> {/** // ? fixture for musicPlayer hook */}
        </>
    )
    const activeSong = songs[ slider.activeIndex ]

    return(
        <>
            <PortfolioWidgets
                social={social} 
                incrementSlider={slider.increment}
                decrementSlider={slider.decrement}
            />
            <audio id="player" src={fromCDN(activeSong.track.url)} preload>
                Sorry your web browser does not support this audio player.
            </audio>
            <section style={{ flex: `1 0` }} className="page__content--block no-scroll">
                <div className="portfolio__description">
                    <h2 className="portfolio__description--header animated fadeInDown slow">
                        <header>{ artist }</header>
                    </h2>
                    <div className="portfolio__description--container">
                        {songs.map((song, id) => {
                            const { title, album } = song
                            return(
                                <PortfolioDescription
                                    key={title}
                                    category={album ? album.title : 'single'}
                                    title={title}
                                    isActive={id === slider.activeIndex}
                                    playSong={() => musicPlayer.setPlaying(true)}
                                />
                            )
                        })}
                    </div>
                </div>
            </section>
            <section style={{ flex: `0 1`, overflow: `visible` }} className="page__content--block">
                <PortfolioNavigation
                    active={slider.activeIndex} 
                    tracks={songs}
                    setIndex={slider.setIndex}
                />
            </section>
            <section style={{ flex: `1 0` }} className="page__content--block">
                <PortfolioSlideshow song={activeSong} player={musicPlayer}/>
            </section>
        </>
    )
}

Portfolio.propTypes = {
    artist: PropTypes.string.isRequired,
    social: PropTypes.shape({
        facebook: PropTypes.string,
        instagram: PropTypes.string,
        twitter: PropTypes.string,
        soundcloud: PropTypes.string,
        youtube: PropTypes.string
    })
}

export default Portfolio