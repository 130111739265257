import { useState, useEffect } from "react"

const useMusicPlayer = (musicPlayerSelector) => {
    const [ duration, setDuration ] = useState()
    const [ curTime, setCurTime ] = useState()
    const [ isPlaying, setPlaying ] = useState(false)
    const [ clickedTime, setClickedTime ] = useState()

    useEffect(() => {
        const audio = document.querySelector(musicPlayerSelector)
        
        const setAudioData = () => {
            setDuration(audio.duration)
            setCurTime(audio.currentTime)
        }

        const setAudioTime = () => setCurTime(audio.currentTime)

        audio.addEventListener("loadeddata", setAudioData)
        audio.addEventListener("timeupdate", setAudioTime)
        
        isPlaying ? audio.play() : audio.pause()

        if(clickedTime && clickedTime !== curTime){
            audio.currentTime = clickedTime
            setClickedTime(null)
        }

        return () => {
            audio.removeEventListener("loadeddata", setAudioData)
            audio.removeEventListener("timeupdate", setAudioTime)
        }
    })
    return {
        isPlaying,
        setPlaying,
        setClickedTime,
        curTime,
    }
}

export default useMusicPlayer