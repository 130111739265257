import { useState, useEffect } from "react"
import debounce from "lodash/debounce"

const useSlideShow = (items) => {
    const [ activeIndex, setIndex ] = useState(0)
    
    // ? Slider implemented as a circular buffer
    const slider = {
        activeIndex,
        setIndex,
        increment : () => setIndex(activeIndex < items.length - 1 ? activeIndex + 1 : 0),
        decrement : () => setIndex(activeIndex > 0 ? activeIndex - 1 : items.length - 1)
    }

    useEffect(() => {
        const handleWheelEvent = debounce(slider.increment, 500)
        window.addEventListener("wheel", handleWheelEvent, { passive: true })
        return () => window.removeEventListener("wheel", handleWheelEvent)
    })

    return slider
}

export default useSlideShow