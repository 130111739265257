import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

const GET_FEATURED_SONGS = gql`
    query getAllFeaturedSongs {
        songs(where: { isFeatured: true }) {
            title
            track{
                url
            }
            coverImage{
                url
            }
            album{
                title
                coverImage{
                    url
                }
            }
            artists{
                name
            }
            producers{
                name
            }
        }
    }
`

const useFeaturedSongsList = () => {
    const { data, loading, error } = useQuery(GET_FEATURED_SONGS)
    if(error){ console.error(error) }
    return { data, loading, error }
}
export default useFeaturedSongsList