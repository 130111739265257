import React from "react"
import PropTypes from "prop-types"

const Description = ({ category, title, isActive, playSong }) => {
    return(
        <div 
            className="portfolio__description--slide animated fadeInUp"
            style={{ display: isActive ? 'block' : 'none' }}
        >
            <span className="portfolio__description--category animated fadeInDown slow delay-1s">
                { category }
            </span>
            <h4 className="portfolio__description--title animated fadeIn slower delay-2s">
                { title }
            </h4>
            <a className="portfolio__description--cta animated fadeIn delay-3s">
                <span onClick={playSong}>Take a Listen</span>
                <i className="fas fa-plus"/>
            </a>
        </div>
    )
}

Description.propTypes = {
    category: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    playSong: PropTypes.func.isRequired
}

export default Description